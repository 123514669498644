<template>
  <b-overlay :show="show_loader" rounded="sm">
    <b-row>
      <b-col v-for="(item, index) in items" md="12" :key="index">
        <b-row>
          <b-col md="7" lg="8" xl="8">
            <b-card no-body>
              <b-card-body>
                <b-card-title>{{ $t(index) }}</b-card-title>
              </b-card-body>
              <b-card-body>
                <b-row>
                  <input-file
                    v-for="data in item"
                    :key="data.id"
                    :ref="data.slug"
                    :data="data"
                    @open_editor="openEditor"
                  ></input-file>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="5" lg="4" xl="4">
            <b-card>
              <b-row>
                <b-col md="12">
                  <b-img
                    v-if="index === 'logo'"
                    :src="
                      require(`@/assets/images/pages/poster/wall-logo-small.jpg`)
                    "
                    style="width: 100%; object-fit: contain; margin-top: 20px;"
                  >
                  </b-img>
                  <b-img
                    v-else
                    :src="
                      require(`@/assets/images/pages/poster/sponsor-small.jpg`)
                    "
                    style="width: 100%; object-fit: contain; margin-top: 20px;"
                  >
                  </b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <modal-editor
        :image="file"
        :type="type"
        :width="width"
        :height="height"
        @onSave="showPreview"
        @close="abortImageEdit"
      >
      </modal-editor>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import inputFile from "./input-img-preview.vue";
import ModalEditor from "../editor.vue";

import { getAllPersonalizations } from "@api/personalizations";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BImg,
    BButton,
    inputFile,
    ModalEditor,
    BOverlay,
  },
  data() {
    return {
      file: null,
      type: "",
      width: 0,
      height: 0,
      show_loader: true,
      items: {},
    };
  },
  created() {
    this.getPersonalizationsData();
  },
  methods: {
    getPersonalizationsData() {
      this.show_loader = true;
      const $this = this;
      getAllPersonalizations()
        .then((data) => {
          delete data.hall;
          $this.items = data;
          $this.show_loader = false;
        })
        .catch(() => {
          this.$router.push({ path: `/error-404/` });
          $this.show_loader = false;
        });
    },
    openEditor(file, type) {
      this.file = file;
      this.type = type;
      this.width = 640;
      this.height = 480;
      this.$bvModal.show("modal-editor");
    },
    abortImageEdit(type) {
      this.$refs[type][0].file = null;
    },
    showPreview(type, imageUrl, image = null) {
      this.$refs[type][0].file = image;
      this.$refs[type][0].preview_image = imageUrl;
      this.$refs[type][0].saveImage();
    },
  },
};
</script>

<style lang="scss"></style>
